
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CopyToClipboardComponent from '@/components/CopyToClipboard/CopyToClipboardComponent.vue';
import CopyHtmlToClipboardComponent from '@/components/CopyToClipboard/CopyHTMLToClipboardComponent.vue';

@Component<EmailModalComponent>({
  components: {
    ButtonComponent,
    CopyToClipboardComponent,
    CopyHtmlToClipboardComponent,
    Modal
  }
})
export default class EmailModalComponent extends Vue {
  @Prop({ default: '' })
  readonly to!: string;

  @Prop({ default: '' })
  readonly subject!: string;

  @Prop()
  readonly title?: string;

  show = false;
  resolve: any = () => void 0;

  close(): void {
    this.show = false;
    this.resolve();
  }

  public open(): Promise<void> {
    this.show = true;
    return new Promise((resolve) => {
      this.resolve = resolve;
    });
  }

  get mailTo(): string {
    return `mailto:${this.to}?subject=${this.subject}`;
  }
}
