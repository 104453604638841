export enum EExitReasonTypes {
  ACADEMICS = 'Academics',
  AIR_SICKNESS = 'Air Sickness',
  AIR_WORTHINESS = 'Air Worthiness',
  DISCIPLINARY = 'Disciplinary',
  GRADUATE = 'Graduate',
  GRADUATE_NON_FTU = 'Graduate (Non-FTU)',
  HUMANITARIAN = 'Humanitarian',
  MEDICAL = 'Medical',
  MEDICAL_HOLD = ' Medical HOLD',
  OTS_PT_FAILURE = 'OTS PT Failure',
  SIE = 'SIE',
  WASHOUT = 'Washout'
}
