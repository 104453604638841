var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "email-container" }, [
      _c("p", [_vm._v("Greetings!")]),
      _c("p", [
        _vm._v(
          " I will be assisting you with your transfer back to your sponsoring unit. In an effort to have a seamless transition and prevent any pay, benefit or training stoppage, please complete the following instructions: "
        ),
      ]),
      _c("p", [
        _vm._v(
          " Complete and return the attached AF 1288 as soon as possible using the instructions below. "
        ),
      ]),
      _c("p", [_vm._v("Complete Page 1")]),
      _c("p", [_vm._v("Blocks 11/12: leave blank")]),
      _c("p", [_vm._v('Blocks 22/24: answer "have/have not" questions')]),
      _c("p", [_vm._v("Blocks 21-26: initial")]),
      _c("p", [_vm._v("Block 27: sign & date")]),
      _c("p", [
        _vm._v(
          " Please complete the attached outprocessing checklist and ensure your POC is added to the checklist and this email (cc'd). "
        ),
      ]),
      _c("p", [
        _vm._v(
          " Your RSC needs to be updated in accordance with AFRCI 36-2102, please sign the attached RSC and return to us as a part of your outprocessing package. "
        ),
      ]),
      _c("p", [
        _vm._v(" Please attach your AFFMS II Fitness Tracker to this email ("),
        _c(
          "a",
          {
            staticClass: "text-blue-500",
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://affms.us.af.mil/affms/ui/dashboard.jsp",
            },
          },
          [_vm._v("https://affms.us.af.mil/affms/ui/dashboard.jsp")]
        ),
        _vm._v(
          "). If you cannot download your fitness tracker, please include your last test date and score on the outprocessing checklist. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " Lastly, your POC for your DD214 has been cc'd. Please reach out to them with any questions regarding the DD214 process. Once I have received the requested items, I will coordinate your transfer with your sponsoring unit. "
        ),
      ]),
      _c("p", [_vm._v("V/R,")]),
      _c("p", [
        _vm._v(" JANETTE M. LOPEZ, TSgt, USAF"),
        _c("br"),
        _vm._v(" 340 FTG, Undergraduate Flying Training"),
        _c("br"),
        _vm._v(" 375 J Street West, Ste 1 (Bldg 863)"),
        _c("br"),
        _vm._v(" JBSA-Randolph, TX 78150-4426"),
        _c("br"),
        _vm._v(" COMM: "),
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "tel:210-652-6107",
            },
          },
          [_vm._v("(210) 652-6107")]
        ),
        _c("br"),
      ]),
      _c("p", [_vm._v("340th UFT Sharepoint (website):")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://randolph.eis.aetc.af.mil/340FTG/UFT/SitePages/Home.aspx",
            },
          },
          [_vm._v("340th UFT Sharepoint")]
        ),
      ]),
      _c("p", [_vm._v("Youtube: 340 FTG Public Affairs:")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://www.youtube.com/channel/UCByIkPqNHL7e3oHjtWEYg4Q",
            },
          },
          [_vm._v("340 FTG Public Affairs")]
        ),
      ]),
      _c("p", [_vm._v("340 FTG WorkFlow Mailbox:")]),
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "mailto:340FTG.UFT.Workflow@us.af.mil",
            },
          },
          [_vm._v("340FTG.UFT.Workflow@us.af.mil")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }