var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex flex-col w-full h-full" }, [
    _c(
      "div",
      { ref: "containerRef", staticClass: "p-8 overflow-auto" },
      [_vm._t("default")],
      2
    ),
    _c(
      "div",
      { staticClass: "flex flex-shrink-0 w-full gap-2 p-8 border-t" },
      [
        _c(
          "button-component",
          {
            attrs: {
              "data-cy": "copyHTMLtoClipboardButton",
              bg: "white",
              textOrBorderColor: "val-button-blue",
              title: _vm.title,
            },
            on: { click: _vm.handleButtonClick },
          },
          [_vm._v(_vm._s(_vm.buttonText))]
        ),
        _vm._t("action-buttons-left"),
        _c(
          "div",
          { staticClass: "ml-auto" },
          [_vm._t("action-buttons-right")],
          2
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }