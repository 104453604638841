var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _vm.show
        ? _c("modal", { attrs: { size: "large" }, on: { close: _vm.close } }, [
            _c(
              "div",
              { staticClass: "flex flex-col h-full" },
              [
                _c(
                  "div",
                  { staticClass: "p-8 pb-0" },
                  [
                    _vm.title
                      ? _c("h3", { staticClass: "mb-4" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "text-sm" }, [_vm._v("To")]),
                    _c("copy-to-clipboard-component", {
                      attrs: {
                        "data-cy": "copyEmailToClipboardButton",
                        content: _vm.to,
                      },
                    }),
                    _c("div", { staticClass: "mt-4 text-sm" }, [
                      _vm._v("Subject"),
                    ]),
                    _c("copy-to-clipboard-component", {
                      attrs: {
                        "data-cy": "copyTrackingNoToClipboardButton",
                        content: _vm.subject,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "copy-html-to-clipboard-component",
                  {
                    staticClass: "h-full overflow-auto max-h-96",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "action-buttons-right",
                          fn: function () {
                            return [
                              _c(
                                "button-component",
                                {
                                  attrs: {
                                    bg: "val-button-blue",
                                    textOrBorderColor: "white",
                                  },
                                  on: { click: _vm.close },
                                },
                                [_vm._v("Done")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "action-buttons-left",
                          fn: function () {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "flex items-center px-4 text-sm text-blue-500 no-underline border border-blue-500 rounded",
                                  attrs: { target: "_blank", href: _vm.mailTo },
                                },
                                [_vm._v("Create Email")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1830733423
                    ),
                  },
                  [_vm._t("default")],
                  2
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }