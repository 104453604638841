
import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { selectElementContents } from '@/util/download-csv';

@Component<CopyHTMLToClipboardComponent>({
  components: {
    ButtonComponent
  }
})
export default class CopyHTMLToClipboardComponent extends Vue {
  @Prop({ default: 'Copy content to clipboard' })
  title!: string;

  @Prop({ default: 'Copy Content' })
  buttonText!: string;

  $refs!: {
    containerRef: HTMLDivElement;
  };

  handleButtonClick(): void {
    selectElementContents(this.$refs['containerRef']);
    this.$store.dispatch('snackBarModule/enqueue', {
      message: 'Content has been copied to the clipboard.'
    });
  }
}
