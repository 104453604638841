var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      staticClass: "flex w-full p-4 bg-white border rounded hover:bg-blue-50",
      attrs: { title: `Copy ${_vm.content} to clipboard` },
      on: { click: _vm.copyText },
    },
    [
      _c(
        "span",
        {
          ref: "contentRef",
          staticClass: "pr-2 text-xs text-left text-blue-500",
        },
        [_vm._v(_vm._s(_vm.content))]
      ),
      _c("icon-component", {
        staticClass: "flex-shrink-0 my-auto ml-auto text-gray-400",
        attrs: { width: 16, height: 16, name: "copy" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }