import { render, staticRenderFns } from "./EmailModalComponent.vue?vue&type=template&id=bbd841c0&scoped=true&"
import script from "./EmailModalComponent.vue?vue&type=script&lang=ts&"
export * from "./EmailModalComponent.vue?vue&type=script&lang=ts&"
import style0 from "./EmailModalComponent.vue?vue&type=style&index=0&id=bbd841c0&scoped=true&nonce=fc57f1ecde28e7fa80cb660b3e8b61bf61fc1406e7f97370c3917b09a6086b9f&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbd841c0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/src/services/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bbd841c0')) {
      api.createRecord('bbd841c0', component.options)
    } else {
      api.reload('bbd841c0', component.options)
    }
    module.hot.accept("./EmailModalComponent.vue?vue&type=template&id=bbd841c0&scoped=true&", function () {
      api.rerender('bbd841c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Modals/Email/EmailModalComponent.vue"
export default component.exports