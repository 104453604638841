
import { Component, Prop, Vue } from 'vue-property-decorator';
import { selectElementContents } from '@/util/download-csv';
import IconComponent from '@/components/IconComponent.vue';

@Component<CopyToClipboardComponent>({
  components: {
    IconComponent
  }
})
export default class CopyToClipboardComponent extends Vue {
  @Prop({
    default: ''
  })
  content!: string | number | Date | boolean;

  $refs!: {
    contentRef: HTMLElement;
  };

  copyText(): void {
    selectElementContents(this.$refs.contentRef);
    this.$store.dispatch('snackBarModule/enqueue', {
      message: `Copied ${this.content} to the clipboard.`
    });
  }
}
